import React from 'react'
import Layout from '../components/layout'


const NotFoundPage = () => (
  <Layout>
    <h1>[PUNK] NOT FOUND</h1>
    <p>no page here </p>

    <hr></hr>

    <h2><a href="https://www.punkhunt.com">Go to PunkHunt.com</a></h2>

  </Layout>
)

export default NotFoundPage
